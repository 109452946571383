.navbar {
  position: relative;
  top: 0;
  left: 0;
  width: auto;
  background-color: #333;
  color: #fff;
  padding: 10px;
  z-index: 1000;
}

.menu-icon, .close-menu-button {
  font-size: 50px;
  background-color: transparent;
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.menu-list {
  list-style-type: none;
  padding: 0;
  text-align: left;
  font-size: 1.5em;
}

.menu-city {
  font-size: 1.5em;
}

.menu-restaurant {
  font-size: 1.em;
  padding-left: 15px;
}

.menu-list li {
  margin: 20px 0;
}

.menu-list a {
  color: #fff;
  text-decoration: none;
}

.submenu ul {
  list-style-type: none;
  padding-left: 20px;
  font-size: 1.2em;
}



/* Breadcrumbs */

.breadcrumb-outer-container {
  background-color: #333;
  margin: 0 auto;
}

.breadcrumb-container {
  max-width: 2048px;
  margin: 0 auto;
}

.breadcrumb {
  background-color: #333;
  list-style: none;
  padding: 16px 0 16px 16px;
  margin: 0;
  display: flex;
  color: #ffffff;
}


.breadcrumb a {
  color: #ffffff;
}

.breadcrumb-item {
  margin-right: 5px;
}

.breadcrumb-item::after {
  content: ">";
  margin-left: 5px;
}

.breadcrumb-item:last-child::after {
  content: "";
}

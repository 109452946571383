.menu-wrapper {
  font-family: 'Open Sans', 'Arial', sans-serif;
  color: #212529;
  max-width: 600px;
  border-radius: 4px;
}

.menu-wrapper a {
  font-family: 'Open Sans', 'Arial', sans-serif;
  color: #212529;
  text-decoration: none;
}

.day:not(:last-child) {
  margin-bottom: 20px;
}

.day-name {
  font-size: 1.5em;
  color: #2b4546;
}

.menu-item {
  font-size: 1em;
  color: #212529;
}

.toggle-button {
  background-color: #2b4546;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-button:hover {
  background-color: #0056b3;
}

.city {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: -1;
}

.city-list {
  list-style-type: none;
  padding: 0;
  text-align: left;
  font-size: 1.5em;
}

.city-list a {
  color: #fff;
  text-decoration: none;
}

.city-restaurant {
  font-size: 1.em;
  padding-left: 15px;
}
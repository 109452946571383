.restaurant {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.image-container {
  width: 100%;
  max-height: 600px;
  min-height: 200px;
  height: 30vh;
  overflow: hidden;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 2048px; /* This will ensure the columns never become wider than 2048px */
  margin: 0 auto; /* This will center the columns if the screen is wider than 2048px */
  flex-grow: 1;
}

.column {
  flex: 1 0 21rem; /* This will make the columns stack on top of each other on smaller screens */
  margin: 1rem;
  padding: 1rem 2rem;
  background-color: #f5f5f5;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

}

.column-1 {
  background-color: #2b4546;
  color: white;
}

.column-1 a {
  color: white;
}

.column-2 {
  background-color: #f5ebdb;
  color: #232425;
}

.column-2 a {
  color: #232425;
}